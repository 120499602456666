import React from "react";
import {
  //AboutSection,
  //ArticlesSection,
  ContactSection,
  //HeroSection,
  //InterestsSection,
  Page,
  ProjectsSection,
  Seo,
} from "gatsby-theme-portfolio-minimal";

export default function IndexPage() {

  const subheading = <>
    Everyone has a few unfinished projects. There is no shame in that!
    <ul>
      <li>It's good to try out new things without having to make a commitment to take them to completion.</li>
      <li>And it's good to move on if things don't turn out as you hoped - or if you simply lose interest or get drawn towards other things.</li>
    </ul>
    Here are some things I started, but may never finish...
    </>

  return (
    <>
      <Seo title="Diarmid Mackenzie" />
      <Page>
        {/*<HeroSection sectionId="hero" />*/}
        {/*<ArticlesSection sectionId="articles" heading="Latest Articles" sources={['Medium']} />*/}
        {/*<AboutSection sectionId="about" heading="About Me" />*/}
        {/*<InterestsSection sectionId="interests" heading="Interests" />*/}
        <ProjectsSection sectionId="projects" heading="Unfinished Projects" subheading={subheading} section={1}/>
        <ContactSection sectionId="github" heading="Contact" />
      </Page>
    </>
  );
}
